.contact-main{
  width: 99vw;
  padding: 0px 10px;
  margin: 0px auto;
}
.basic-contact {
  margin: 0 auto;
  max-width: 1024px;
  width: 90%;
  min-height: calc(100vh - 3rem - 150px);

}

.contact-heading-div {
  display: flex;
}





  .blog-heading-div {
    display: flex;
  }

  .address-heading-div {
    display: flex;
    margin-top: 80px;
  }

  .blog-heading-div > * {
    flex: 1;
  }

  .contact-heading-div > * {
    flex: 1;
  }

  .address-heading-div > * {
    flex: 1;
  }

  .contact-heading-img-div {
    margin-left: 13%;
    margin-bottom: 8%;
    margin-top: 4%;
  }

  .profile-pic {
    box-shadow: 0 5px 15px #353535;
    border-radius: 0.6em;
    max-width: 100%;
  }

  .blog-heading-img-div {
    align-items: center;
    justify-content: center;
  }

  .contact-heading-text-div {
    text-align: center;
  }

  .blog-heading-text-div {
    text-align: center;
  }

  .address-heading-text-div {
    text-align: center;
  }

  .contact-heading-img-div > * {
    max-width: 100%;
    height: auto;
  }

  .blog-heading-img-div > * {
    max-width: 100%;
    height: auto;
  }

  .contact-heading-text {
    font-size: 56px;
    font-weight: 400;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    line-height: 1.1;
    text-align: center;
    margin-top: 60px;
  }

  .blog-heading-text {
    font-size: 56px;
    font-weight: 400;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    line-height: 1.1;
    text-align: center;
    margin-top: 40px;
  }

  .address-heading-text {
    font-size: 30px;
    font-family: "Google Sans Regular";
    text-align: center;
    margin-bottom: 10px;
  }

  .contact-detail-text {
    font-size: 15px;
    line-height: 20px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: left;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    color: #444;
    font-size: 0.9rem;
    line-height: 1.8;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }

  .blog-header-detail-text {
    font-size: 20px;
    line-height: 30px;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }

  .general-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .blogsite-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .address-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0;
  }

  .general-btn {
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    padding: 15px 15px;
    margin-top: 25px;
    border-radius: 4px;
    border-width: 0px;
    width: 200px;
    height: 50px;
    font-weight: bold;
    font-family: "Google Sans Regular";
    font-size: 17px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

























@media (max-width: 1380px) {
  .contact-heading-text {
    font-size: 50px;
    margin-top: 60px;
  }
  .contact-detail-text {
    font-size: 15px;
    line-height: 20px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: left;
    zoom: 0.9;
    text-rendering: optimizeLegibility;
    color: #444;
    font-size: 0.9rem;
    line-height: 1.8;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
  .blog-heading-text {
    font-size: 50px;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .basic-contact {
    width: 90%;
    overflow: hidden;
    padding: 1%;
    margin: 0 auto;
    min-height: calc(100vh - 200px);
  }
  .contact-heading-text-div {
    margin-bottom: 30px;
  }
  .contact-heading-text {
    font-size: 30px;
    margin-top: 0px;
  }

  .blog-heading-text {
    font-size: 30px;
    margin-top: 0px;
  }

  .contact-heading-img-div {
    margin-left: auto;
    margin-right: auto;
  }

  .address-heading-text {
    font-size: 20px;
  }

  .blog-heading-div {
    flex-direction: column-reverse;
    margin-top: 60px;
  }

  .address-heading-div {
    flex-direction: column;
    margin-top: 60px;
  }

  .blog-heading-text-div {
    margin-top: 40px;
  }

  .address-heading-text-div {
    margin-top: 40px;
  }

  .contact-heading-div {
    flex-direction: column;
  }

  .contact-detail-text {
    font-size: 16px;
    line-height: 18px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: left;
    text-rendering: optimizeLegibility;
    color: #444;
    font-size: 0.9rem;
    line-height: 1.8;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }

  .blog-header-detail-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
}
