.experience-main {
  color: rgba(0, 0, 0, 0.6);
}

ul li {
  list-style: none;
  text-rendering: optimizeLegibility;
  color: #444;
  font-size: 0.9rem;
  line-height: 1.8;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
p {
  text-rendering: optimizeLegibility;
  color: #444;
  font-size: 0.9rem;
  line-height: 1.8;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.basic-experience {
  margin: 0 5%;
  min-height: calc(100vh - 3rem - 150px);
}

.experience-heading-div {
  display: flex;
  max-width: 1024px;
  margin: 0 auto;
}

.experience-heading-div > * {
  flex: 1;
}

.experience-heading-img-div {
  align-items: center;
  justify-content: center;
}

.experience-heading-text-div {
  text-align: left;
}

.experience-heading-img-div > * {
  max-width: 100%;
  height: auto;
  text-align: center;
}

.experience-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 80px;
  text-rendering: optimizeLegibility;
  color: #444;
  font-size: 0.9rem;
  line-height: 1.8;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.experience-heading-sub-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-bottom: 10px;
}

.experience-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

@media (max-width: 1380px) {
  .experience-heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .experience-heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .experience-heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .experience-heading-sub-text {
    font-size: 20px;
  }

  .experience-heading-div {
    flex-direction: column;
    max-width: 700px;
    margin: 0 auto;
  }

  .experience-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
}
