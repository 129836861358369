.information-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.product-table {
  border-collapse: collapse;
  min-width: 300px;
  width: 70%;
  margin: 0 auto;
  color: #404040;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  table-layout: fixed; /* Ensures equal column width */
}
.product-table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures equal column width */
}

.product-table th {
  text-align: center;
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  font-weight: bold;
  padding: 10px;
}
.product-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
}
@media (max-width: 768px) {
  .product-table {
    width: 100%;
  }

  .information-container{
    flex-direction: column;
    align-items: center;  
  }
  .information {
    flex: 1 1 100%; /* Columns stack vertically on smaller screens */
    margin-bottom: 20px;
  }
}
