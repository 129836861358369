.projects-main {
  text-align: center;
  width: 99vw;
}

.basic-projects {
  margin-left: 5%;
  margin-right: 5%;
  max-width: 1024px;
  margin: 0 auto;
}

.projects-heading-div {
  display: flex;
}

.projects-heading-div > * {
  flex: 1;
}

.projects-heading-img-div {
  align-items: center;
  justify-content: center;
}

.projects-heading-text-div {
  text-align: center;
}

.projects-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.projects-heading-text {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 80px;
  text-align: left;
}

.subTitle {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  font-size: 18px;
  font-family: "Google Sans Regular";
  margin-bottom: 10px;
  text-rendering: optimizeLegibility;
  color: #444;
  font-size: .9rem;
  line-height: 1.8;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;

}


.repo-cards-div-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem 1rem;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 60px;
}

@media (max-width: 1380px) {
  .projects-heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .projects-heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .projects-heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .projects-heading-sub-text {
    font-size: 20px;
  }

  .projects-heading-div {
    flex-direction: column;
  }

  .projects-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    margin-left: 0px;
    line-height: normal;
    text-align: center;
  }

  .repo-cards-div-main {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
  }
}
